@keyframes scanline {
  0% {
    top: 0;
  }

  100% {
    top: 100%;
  }
}

@keyframes skew {
  0% {
    transform: skewX(0.5deg);
  }

  8% {
    transform: skewX(1deg) scale(1.0001);
  }

  15% {
    transform: skewX(0.6deg) skewY(-0.05deg);
  }

  30% {
    transform: skewX(0.6deg);
  }

  100% {
    transform: skewX(0.1deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.005);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes blur {
  50% {
    color: darken($output-color, 10%);
    text-shadow: 2px 0 7px lighten($output-text-shadow, 50%);
  }
}

@keyframes glow {
  50% {
    transform: scale(1.2);
  }
}
