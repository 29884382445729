$background-color: #000;
$color: rgb(0, 0, 0);
$base-unit: 1em;
$font-size: 20px;
$chassis-background: #2e2e2e;
$chassis-margin: $base-unit * 3;
$interlace-background: linear-gradient(rgba(255, 201, 24, 0.2) 0%, #000 100%);
$interlace-size: 4px;
$envelope-background: rgba(#000, 0.25);
$envelope-margin: 2em;
$scanline-background: linear-gradient(to bottom, transparent 0%, rgba(255, 250, 250, 1) 50%, rgb(239, 255, 100) 50%, transparent 100%);
$output-font-family: 'Terminus';
$output-color: rgb(255, 201, 24);
$output-text-shadow: rgba(255, 201, 24, 0.0);
$output-text-blur-min: 0px;
$output-text-blur-max: 1px;
$output-line-height: 1.0;
$gradient-bottom: rgb(0, 0, 0);
$gradient-inner: rgb(37, 37, 37);

@mixin fill($top: 0, $left: 0, $right: 0, $bottom: 0) {
  position: absolute;
  left: $left;
  top: $top;
  right: $right;
  bottom: $bottom;
}
