
@font-face {
font-family: Terminus;
font-weight: normal;
font-style: normal; 
src: url('fonts/Terminus.ttf') format('truetype');
}

#crttext {
    
  font-family: 'Terminus', 'Courier New';
  font-weight: normal;
  font-style: normal; 
    
    white-space: nowrap;  overflow: auto;
}