@media (max-width: 1280px) {
  #crt {
    width: 900vw;
    height: 90vh;
    font-size: $font-size / 2.3;
  }
  
#terminal {
  
    #crttext {
      text-shadow: 0 0 17px rgba(255, 201, 24, 1.0);
    }
  }
}
